import React, { useEffect, useState } from 'react';
import './Profile.css';

function Profile() {
  const [cvUrl, setCvUrl] = useState(null);


  useEffect(() => {
    fetch('/api.php?endpoint=cv')
      .then(response => response.json())
      .then(data => {
        if (data.file_path) {
          setCvUrl(`https://guiotmatthieu.fr/${data.file_path}`);
        }
      })
      .catch(error => console.error('Error fetching CV:', error));
  }, []);
  

  return (
    <div className='profile'>
      <h1>Mon CV</h1>
      {cvUrl ? (
        <div className='pdf-container'>
          <iframe
            src={cvUrl}
            title="CV"
            width="100%"
            height="650px"
            style={{ border: 'none' }}
          ></iframe>
          <a href={cvUrl} download="Matthieu_Guiot_CV.pdf" className="download-btn">
            Télécharger mon CV
          </a>
        </div>
      ) : (
        <p>Chargement du CV...</p>
      )}
    </div>
  );
}

export default Profile;
