import React from 'react';
import './Home.css';
import './SkillCard.css';
import html from '../../assets/Skills/html.png';
import css from '../../assets/Skills/css.png';
import js from '../../assets/Skills/js.png';
import react from '../../assets/Skills/react.png';
import node from '../../assets/Skills/node.png';
import php from '../../assets/Skills/php.png';
import mysql from '../../assets/Skills/mysql.png';
import reseau from '../../assets/Skills/reseau.png';
import python from '../../assets/Skills/python.png';
import c from '../../assets/Skills/C.png';


const skillsData = [
  { name: 'HTML', image: html },
  { name: 'CSS', image: css },
  { name: 'JavaScript', image: js },
  { name: 'React', image: react },
  { name: 'Node', image: node },
  { name: 'PHP', image: php },
  { name: 'MySQL', image: mysql },
  { name: 'Réseau', image: reseau },
  { name: 'Python', image: python },
  { name: 'C', image: c },
  
];

function SkillCard({ skill, image }) {
  return (
    <div className="skill-card">
      <img src={image} alt={skill} className="skill-icon" />
      <h3>{skill}</h3>
    </div>
  );
}

function Home() {
  return (
    <div className='home'>
      <div className='top'>
        <h1>Matthieu Guiot</h1>
      </div>
      <p className="about-me">
        Bonjour ! Je suis Matthieu Guiot un étudiant de 3e année engagé et passionné, actuellement en Bachelor Développement, option Cybersécurité, 
        à la Guardia Cybersecurity School de Lyon. Originaire de Nouvelle-Calédonie, j'ai quitté les plages de mon île natale 
        pour m'immerger dans l'univers dynamique de l'informatique et concrétiser mes aspirations professionnelles.
        <br></br><br></br>
        Diplômé d'un Bac Scientifique, j'ai choisi de me spécialiser dans le développement et la cybersécurité, 
        disciplines qui combinent créativité, logique et innovation. Mon parcours est guidé par une curiosité insatiable 
        et une détermination à explorer les multiples facettes de l'informatique. Pour moi, réaliser ses ambitions nécessite 
        une vision claire, une exécution rigoureuse et un engagement constant. Chaque projet entrepris est une promesse 
        que je m'efforce toujours de tenir.
        <br></br><br></br>
        En dehors de mes études et projets numériques, je suis également passionné par le bricolage et la création manuelle. 
        Ces activités, bien qu'apparemment éloignées de l'informatique, nourrissent mon esprit d'innovation et pourraient, un jour, 
        aboutir à une fusion unique entre technologie et artisanat.
        <br></br><br></br>
        Mon tempérament ouvert et extraverti me permet de m'intégrer rapidement dans un environnement collaboratif. 
        Travailler avec moi, c'est découvrir un développeur rigoureux, créatif et résolument tourné vers la résolution 
        de problèmes, mais également un coéquipier enthousiaste, prêt à relever tous les défis avec optimisme et efficacité.
        <br></br><br></br>
        Quant à ma plus grande faiblesse ? Je vous invite à la découvrir par vous-même. Après tout, chaque individu 
        mérite de garder une part de mystère à explorer, n'est-ce pas ?
        </p>
      <div className='top-skills'>
        <h2>Mes Compétences</h2>
      </div>
      <div className='skills'>
        {skillsData.map(({ name, image }, index) => (
          <SkillCard key={index} skill={name} image={image} />
        ))}
      </div>
    </div>
  );
}

export default Home;